import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      width: '100%',
      height: $data.tempheight,
      background: '#1C1E41',
      overflow: 'auto'
    }),
    class: "box"
  }, [_cache[10] || (_cache[10] = _createElementVNode("div", {
    style: {
      "padding": "32px 0 0 32px"
    },
    class: "titsty1"
  }, "E-Fapiao", -1)), _cache[11] || (_cache[11] = _createElementVNode("div", {
    style: {
      "padding": "0 0 0 32px"
    },
    class: "titsty2"
  }, "E发票", -1)), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[0] || (_cache[0] = $event => {
      this.$router.push('kp');
    })
  }, "• 输入开票提取码"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[1] || (_cache[1] = $event => {
      this.$router.push('loading');
    })
  }, "• Loading"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[2] || (_cache[2] = $event => {
      this.$router.push('fpkj');
    })
  }, "• 开票申请页"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[3] || (_cache[3] = $event => {
      this.$router.push('kpconfirm');
    })
  }, "• 信息确认页"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[4] || (_cache[4] = $event => {
      this.$router.push('dealing');
    })
  }, "• 开票申请处理中"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[5] || (_cache[5] = $event => {
      this.$router.push('searchinvoice');
    })
  }, "• 查询发票"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[6] || (_cache[6] = $event => {
      this.$router.push('kpsuccess');
    })
  }, "• 开票成功页"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[7] || (_cache[7] = $event => {
      this.$router.push('showFapiao');
    })
  }, "• 查看发票"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[8] || (_cache[8] = $event => {
      this.$router.push('kpfailed');
    })
  }, "• 开票失败"), _createElementVNode("div", {
    class: "navisty",
    onClick: _cache[9] || (_cache[9] = $event => {
      this.$router.push('systemerr');
    })
  }, "• 失败画面")], 4);
}